@import '../styles/variables.css';

.footer {
    padding: 2rem 0;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.footer-light {
    background-color: var(--bg-secondary);
    color: var(--text-color);
}

.footer-dark {
    background-color: var(--bg-secondary);
    color: var(--text-color);
}

.footer-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-bottom: 1rem;
}

.footer-link {
    color: var(--text-secondary);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
    padding: 0.5rem 1rem;
}

.footer-link:hover {
    color: var(--primary-color);
}

.footer-theme-toggle {
    margin-left: 1rem;
    display: flex;
    align-items: center;
}

.copyright {
    color: var(--text-secondary);
    margin-bottom: 0;
    font-size: 0.9rem;
}

@media (max-width: 767px) {
    .footer {
        padding: 1.5rem 0;
    }
    
    .footer-nav {
        flex-direction: column;
        gap: 1rem;
    }
    
    .footer-theme-toggle {
        margin-left: 0;
        margin-top: 1rem;
    }
} 