.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home-page .btn-primary {
  background-color: #007bff;
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.home-page .btn-primary:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .home-page h1 {
      font-size: 3rem; /* Adjust font size on smaller screens */
  }
  
  .home-page p {
      font-size: 1.25rem;
  }
}

body {
  font-family: 'Poppins', sans-serif;
}

/* For specific elements */
.home-page h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700; /* Bold for h1 */
}

.home-page p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Normal weight for paragraphs */
}

html {
  scroll-behavior: smooth;
}

