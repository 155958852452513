.admin-btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  min-width: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: none;
  font-weight: 500;
}

.admin-btn:hover {
  transform: none;
  box-shadow: none;
}

.admin-btn.btn-sm {
  padding: 0.375rem 0.75rem;
  min-width: 80px;
}

.table .admin-btn {
  margin: 0 0.25rem;
}

.card-body .admin-btn {
  margin: 0 0.5rem;
}

.admin-dashboard .btn {
  transition: none !important;
}

.admin-dashboard .btn:hover {
  transform: none !important;
  box-shadow: none !important;
}

.admin-dashboard .dropdown-toggle.btn-primary {
  transition: none !important;
}

.admin-dashboard .dropdown-toggle.btn-primary:hover {
  transform: none !important;
  box-shadow: none !important;
}

.admin-dashboard .dashboard-card {
  transition: none !important;
}

.admin-dashboard .dashboard-card:hover {
  transform: none !important;
} 