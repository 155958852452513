@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@import '../styles/variables.css';

body {
    font-family: 'Poppins', sans-serif;
    background-color: var(--bg-color);
    color: var(--text-color);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.home-page {
    overflow-x: hidden;
    position: relative;
}

/* Add a subtle animated background pattern */
.home-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
        radial-gradient(circle at 25% 25%, rgba(74, 144, 226, 0.1) 1%, transparent 6%),
        radial-gradient(circle at 75% 75%, rgba(57, 201, 194, 0.1) 1%, transparent 6%);
    background-size: 60px 60px;
    opacity: 0.3;
    z-index: -1;
    pointer-events: none;
}

.home-section,
.drivers-section,
.riders-section {
    padding: 6rem 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
}

.home-section {
    background: var(--gradient-primary);
    color: var(--hero-text-color);
    overflow: hidden;
}

/* Add animated shapes to home section */
.home-section::before {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    top: -100px;
    right: -100px;
    animation: float 15s infinite ease-in-out;
}

.home-section::after {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    bottom: -50px;
    left: -50px;
    animation: morph 15s infinite ease-in-out alternate;
}

.drivers-section {
    background-color: var(--bg-color);
    position: relative;
    z-index: 1;
    transition: background-color 0.3s ease;
}

.drivers-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to bottom, rgba(74, 144, 226, 0.1), transparent);
    z-index: -1;
}

/* Dark mode specific styles for drivers section */
[data-theme='dark'] .drivers-section {
    background-color: var(--bg-color);
}

[data-theme='dark'] .drivers-section::before {
    background: linear-gradient(to bottom, rgba(90, 159, 240, 0.2), transparent);
}

[data-theme='dark'] .section-header h2 {
    color: var(--text-color);
}

.riders-section {
    background: var(--gradient-secondary);
    padding: 8rem 0;
    position: relative;
}

.text-section h1 {
    color: var(--hero-text-color);
    font-weight: 800;
    font-size: 4.5rem;
    text-shadow: var(--hero-text-shadow);
    margin-bottom: 1.5rem;
    animation: fadeInUp 1s ease-out;
}

.text-section p {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.3rem;
    line-height: 1.7;
    margin-bottom: 2rem;
    animation: fadeInUp 1s ease-out 0.3s backwards;
}

.image-section img {
    max-height: 80vh;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: var(--card-shadow);
    transform: perspective(1000px) rotateY(-5deg);
    transition: all 0.5s ease;
    animation: float 6s infinite ease-in-out;
}

.image-section:hover img {
    transform: perspective(1000px) rotateY(0deg);
}

.card {
    border: none;
    border-radius: 20px;
    transition: all 0.4s ease;
    background: var(--card-bg);
    backdrop-filter: blur(10px);
    box-shadow: var(--card-shadow);
    overflow: hidden;
    height: 100%;
}

.hover-card:hover {
    transform: translateY(-15px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.card-icon {
    font-size: 3.5rem;
    background: var(--gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;
}

/* Ensure icons are visible in dark mode */
[data-theme='dark'] .card-icon {
    background: var(--gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
    filter: brightness(1.5);
}

.hover-card:hover .card-icon {
    transform: scale(1.2);
}

.card-title {
    color: var(--text-color);
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.card-text {
    color: var(--text-secondary);
    font-size: 1.05rem;
    line-height: 1.6;
}

.btn-primary,
.btn-outline-primary {
    border-radius: 50px;
    padding: 0.85rem 2.5rem;
    font-weight: 600;
    transition: all 0.4s ease;
    position: relative;
    overflow: hidden;
    z-index: 1;
    animation: fadeInUp 1s ease-out 0.6s backwards;
}

.btn-primary::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--gradient-primary);
    z-index: -1;
    transition: all 0.4s ease;
}

.btn-primary:hover,
.btn-outline-primary:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(74, 144, 226, 0.4);
}

.btn-primary:hover::before {
    transform: scale(1.1);
    opacity: 0.9;
}

.riders-section h2 {
    font-size: 3.5rem;
    background: linear-gradient(135deg, var(--text-color), var(--text-secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 4rem;
    text-align: center;
    font-weight: 700;
}

.rider-features {
    background: var(--card-bg);
    border-radius: 30px;
    padding: 3.5rem;
    box-shadow: var(--card-shadow);
    margin-left: 2rem;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.feature-icon {
    font-size: 1.5rem;
    background: var(--gradient-primary);
    color: white;
    padding: 1.2rem;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.8rem;
    box-shadow: 0 10px 20px rgba(74, 144, 226, 0.3);
    transition: all 0.3s ease;
}

.rider-feature {
    margin-bottom: 3rem;
    transition: all 0.4s ease;
    display: flex;
    align-items: flex-start;
}

.rider-feature:hover {
    transform: translateX(15px);
}

.rider-feature:hover .feature-icon {
    transform: scale(1.1) rotate(10deg);
}

.rider-feature h5 {
    font-size: 1.4rem;
    margin-bottom: 0.8rem;
    font-weight: 600;
    color: var(--text-color);
}

.rider-feature p {
    color: var(--text-secondary);
    font-size: 1.05rem;
    line-height: 1.7;
    margin-bottom: 0;
}

.btn-primary {
    background: var(--gradient-primary);
    border: none;
    padding: 1.2rem 3rem;
    font-size: 1.1rem;
    margin-top: 2.5rem;
    letter-spacing: 0.5px;
}

.riders-section img {
    border-radius: 30px;
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 16/10;
    box-shadow: var(--card-shadow);
    transform: perspective(1000px) rotateY(5deg);
    transition: all 0.5s ease;
    animation: float 8s infinite ease-in-out reverse;
}

.riders-section:hover img {
    transform: perspective(1000px) rotateY(0deg);
}

/* Animations */
@keyframes float {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-20px) rotate(2deg);
    }
    100% {
        transform: translateY(0px) rotate(0deg);
    }
}

@keyframes morph {
    0% {
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
    25% {
        border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
    }
    50% {
        border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    }
    75% {
        border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
    }
    100% {
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* New styles for added elements */
.section-header {
    margin-bottom: 4rem;
}

.section-header .badge {
    background: var(--gradient-primary);
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    border: none;
    color: white;
}

[data-theme='dark'] .section-header .badge {
    background: var(--gradient-primary);
    color: white;
    box-shadow: 0 2px 10px rgba(90, 159, 240, 0.3);
}

.divider {
    height: 4px;
    width: 60px;
    background: var(--gradient-primary);
    margin: 1.5rem auto;
    border-radius: 2px;
}

.scroll-indicator {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    opacity: 0.7;
}

.mouse {
    width: 30px;
    height: 50px;
    border: 2px solid white;
    border-radius: 20px;
    position: relative;
    margin-bottom: 10px;
}

.wheel {
    width: 6px;
    height: 6px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    animation: wheel 2s infinite;
}

@keyframes wheel {
    0% {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-50%) translateY(20px);
        opacity: 0;
    }
}

.scroll-arrow {
    display: block;
    width: 10px;
    height: 10px;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(45deg);
    margin: 0 0 3px 0;
    animation: arrow 2s infinite;
}

.scroll-arrow:nth-child(2) {
    animation-delay: 0.2s;
}

.scroll-arrow:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes arrow {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.app-features-section {
    background-color: var(--bg-secondary);
    padding: 6rem 0;
    position: relative;
}

.feature-card {
    background: var(--card-bg);
    border-radius: 20px;
    padding: 2.5rem;
    box-shadow: var(--card-shadow);
    transition: all 0.4s ease;
    height: 100%;
    text-align: center;
}

.feature-card:hover {
    transform: translateY(-15px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.feature-icon-wrapper {
    width: 80px;
    height: 80px;
    background: linear-gradient(135deg, rgba(74, 144, 226, 0.1), rgba(57, 201, 194, 0.1));
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.5rem;
    transition: background 0.3s ease;
}

.feature-card-icon {
    font-size: 2.5rem;
    color: var(--primary-color);
}

.feature-card h4 {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--text-color);
}

.feature-card p {
    color: var(--text-secondary);
    font-size: 1rem;
    line-height: 1.6;
}

.btn-outline-light {
    color: white;
    border: 2px solid white;
    background: transparent;
}

.btn-outline-light:hover {
    background: rgba(255, 255, 255, 0.1);
    color: white;
}

/* Animation for scroll elements */
.animate-on-scroll {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.8s ease-out;
}

.animate-on-scroll.animated {
    opacity: 1;
    transform: translateY(0);
}

/* Dark mode specific adjustments */
[data-theme='dark'] .card,
[data-theme='dark'] .feature-card,
[data-theme='dark'] .rider-features {
    border: 1px solid var(--border-color);
}

[data-theme='dark'] .feature-icon-wrapper {
    background: linear-gradient(135deg, rgba(90, 159, 240, 0.2), rgba(74, 216, 209, 0.2));
}

/* Fix for feature card icons in dark mode */
[data-theme='dark'] .feature-card-icon {
    color: var(--primary-color);
    filter: brightness(1.5);
}

@media (max-width: 991px) {
    .home-section,
    .drivers-section,
    .riders-section,
    .app-features-section {
        padding: 4rem 0;
    }

    .text-section h1 {
        font-size: 3rem;
    }

    .text-section p {
        font-size: 1.1rem;
    }
    
    .rider-features {
        margin-left: 0;
        margin-top: 3rem;
    }
}

@media (max-width: 767px) {
    .home-section,
    .drivers-section,
    .riders-section,
    .app-features-section {
        padding: 3rem 0;
    }

    .text-section h1 {
        font-size: 2.5rem;
    }

    .card {
        margin-bottom: 1.5rem;
    }

    .rider-features {
        padding: 2rem;
    }
    
    .feature-icon {
        width: 3.5rem;
        height: 3.5rem;
        padding: 1rem;
    }
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.icon-dark {
    color: white !important;
    fill: white !important;
    filter: brightness(1.5) !important;
    opacity: 1 !important;
}

[data-theme='dark'] svg {
    color: white;
    fill: white;
}

