.theme-toggle-btn {
    background: none;
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: var(--text-color);
    background-color: var(--toggle-bg);
    transition: all 0.3s ease;
    margin-left: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.theme-toggle-btn:hover {
    transform: rotate(15deg);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.theme-toggle-btn:focus {
    outline: none;
}

/* Light mode icon styles */
[data-theme='light'] .theme-toggle-btn {
    color: #4A90E2;
    background-color: rgba(74, 144, 226, 0.1);
}

/* Dark mode icon styles */
[data-theme='dark'] .theme-toggle-btn {
    color: #FFD700;
    background-color: rgba(255, 215, 0, 0.1);
}

@media (max-width: 767px) {
    .theme-toggle-btn {
        width: 35px;
        height: 35px;
        font-size: 1rem;
    }
} 