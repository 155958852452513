.login-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.login-card {
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 10px;
}

.login-card .card-body {
    padding: 2rem;
}

.login-card h2 {
    color: #333;
    font-weight: 600;
}

.input-group-text {
    background-color: #f8f9fa;
    border-right: none;
}

.form-control {
    border-left: none;
}

.form-control:focus {
    box-shadow: none;
    border-color: #ced4da;
}

.btn-primary {
    background-color: #4a69bd;
    border-color: #4a69bd;
    transition: all 0.3s ease;
}

.btn-primary:hover {
    background-color: #3c55a5;
    border-color: #3c55a5;
}

.alert {
    border-radius: 5px;
}

